import React, { useContext } from 'react';
import { PageDataContext } from '../types';

export const context = React.createContext<PageDataContext>({});

export const PageDataContextProvider = ({ children, value }) => {
  const { pageContext, location, data } = value;
  const { locale, lang, id } = pageContext;
  const alternate_languages = data?.prismicArticle?.alternate_languages;
  return (
    <context.Provider
      value={{
        locale,
        lang: lang ? lang : id,
        location,
        alternate_languages,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const usePageDataContext = () => {
  return useContext(context);
};
